hr {

    color: #DDDDDD;
    background: #DDDDDD;
    fill: #DDDDDD;
    opacity: 1 !important ;
}

.hr-list {
    
    color: #DBDBF4;
    background: #DBDBF4;
    fill: #DBDBF4;   
}

.labelOneBg {
    background: #8889DB;
}

.labelTwoBg {
    background: #D18DEF;
}

.labelThreeBg {
    background: #456ECE;
}

.labelFourBg {
    background: #CED2FF;
}

.bgRedWarning-bubble {
    background-color: #FCE9E8;
}

.bgWarningHelpMessage {
    background-color: #FEFBEC;
}

.bgGray20 {
    background: rgba(221, 221, 221, 0.20);
}

.bannerRadiant {
    background: linear-gradient(90deg, rgba(203, 186, 228, 1) 0%, rgba(175, 176, 227, 1) 100%);
}

.profileBg {
    background: rgb(194, 154, 224);
}


/* Color Pallets from Font */

.progressGreeBg {
    background-color: #58BB82;
}

.brightBlueBg {
    background-color: #CED2FF;
}

.blueTwoBg {
    background-color: #8889DB;
}

.brightBlue {
    background-color: #CED2FF;
}

i {
    color: inherit;
}

.lightVioletBg {
    background-color: #DBDBF4;
}

.lightPurpleBg {
    background-color: #C29AE0;
}

.lightBlueBg {
    background-color: #8889DB;
}

.blueBg {
    background-color: #456ECE;
}

.blueBg20 {
    background-color: rgba(69, 110, 206, 0.25);
}

.skyBg {
    background-color: #5AAFF1;
}

.descPersonalBg {
   background: linear-gradient(#E5F2FF, #FAFAFC);
}

.mobileBackground {
    background: linear-gradient(121deg, #C29AE0, #CED2FF);
 }

 .backgroundShallowBlue {
    background: #ECF0FA 0% 0% no-repeat padding-box;
 }
 
 .blueBubbleColors {
    background-color: #CED2FF;
    color: #456ECE;
}

.brightSkyBg {
    background-color: #5AAFF199;
}

.f9GrayBg {
    background-color: #F9F9FD;
}

.input-border {
    border: 1px solid #CED2FF
}

.input-error-border {
    border: 1px solid #FD0E69;
}

.f9GrayBorder {
    border: 1px solid #CED2FF66;
}

.bs-card-border {
    border: 1px solid #D3EAFF;
}

.brightGrayBg {
    background-color: #DDDDDD;
}

.brightGrayBgOpacity20 {
    background-color: rgba(221,221,221, 0.2);
}

.grayBg {
    background-color: #908F9A;
}

.lightGreyBg {
    background-color: #EFEFF8;
}

.blackBg {
    background-color: #171A22;
}

.lightGreenBg {
    background-color: #16BE7D;
}

.brightGreenBg {
    background-color: #cafae9;
}

.greenBg {
    background-color: #199666;  
}

.greenModalBg {
    background-color: #e7f5f0;
}

.redBg {
    background-color: #FD0E69;
}

.lightRedBg {
    background-color: #f06e69;
}

.warningBg {
    background-color: rgb(254, 251, 236);
}

.lightRedBgOpacity20 {
    background-color: rgba(240, 110, 105, 0.2)
}

.lightRedBgOpacity10 {
    background-color: rgba(240, 110, 105, 0.1)
}

.goldBg {
    background-color: #F2D149;
}

.goldBg20 {
    background-color: rgba(242, 209, 73, 0.1);
}

.bronceBg {
    background-color: #FFFFFF;
}

.whiteBg {
    background-color: #F5F6FF;
}

.lightWhiteBg {
    background-color: #F9F9FC;
}

.assemblyBg {
    background-color: #83C3F4;
}

.EDBBg {
    background-color: #7392DA;
}

.birthdayBg {
    background-color: #A6A6E4;
}

.businessBg {
    background-color: #FDF6DA;
}

.lightYellowBg {
    background-color: #FFFDF5;
}

.personalBg {
    background-color: #DCA9F3;
}

.donutHeaderBg {
    background-color: #ECF0FA;
}

.darkGrayBg {
    background-color: #908F9A;
}

.progressBarBg {
    background-color: #EDEDED;
}

.graphGrayBg {
    background-color: rgba(221, 221, 221, 0.8);
}

/* Fonts on Colors */


.brightBlueColor {
    color: #CED2FF;
}

.lightPurpleColor {
    color: #C29AE0;
}

.lightBlueColor {
    color: #8889DB;
}

.blueColor {
    color: #456ECE;
}

.skyColor {
    color: #5AAFF1;
}

.brightGrayColor {
    color: #DDDDDD;
}

.grayColor {
    color: #908F9A;
}

.unnamedGrayColor {
    color: #dddddd
}

.blackColor {
    color: #171A22 !important;
}

.lightGreenColor {
    color: #16BE7D;
}

.greenColor {
    color: #199666;
}

.redColor {
    color: #FD0E69;
}
.lightRedColor {
    color: #f06e69;
}

.goldWarning-container {
    background: #FDFAED;
    color: #DCB632;
}

.lightRedBackgroundOpacity80 {
    background: #f06e69;
    opacity: 0.8;
}

.goldColor {
    color: #F2D149;
}
.goldBoldColor {
    color : #DCB632 
}
.bronceColor {
    color: #FFFFFF;
}

.whiteColor {
    color: #F5F6FF;
}

.lightWhiteColor {
    color: #FAFAFC;
}

.darkYellowColor {
    color: #DCB632 
}

.navbarBg {
    background-color: #99ADE5 !important ;
}

.reducedColor {
    color: #D18DEF
}

/* SVG fill colors */

.bronceFill > svg > path {
    fill: #FFFFFF
}

.bronceFillG > svg > g > path {
    fill: #FFFFFF
}

/* SVG stroke colors */

.bronceStroke > svg > path {
    stroke: #FFFFFF;
}

.blackStroke > svg > path {
    stroke: #171A22;
}

/* Border colors */

.skyBorder {
    border-style: solid;
    border-width: thin;
    border-color: #5AAFF1 !important;
}
.grayBorderShare {
    border-style: solid;
    border-width: thin;
    border-color:  #DDDDDD !important;
    border: 1px solid;
}

.brightGrayBorder {
    border: 1px solid #DDDDDD;
}

.blueBorder {
    border: 1px solid #456ECE;
}

.bgBlackOpacity5 {
    background-color: rgba(rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), 0.5) !important ;
}

.grayBorder {
    border-color: #908F9A;
}

.redBorder {
    border: 1px solid #FD0E69 !important;
}

.buttonBehavior {
    transition: all 0.5s ease-in-out;
}

.buttonBehavior:hover {
    opacity: 0.5;
    background: #5AAFF1 !important ;
    color: white !important;
    transition: all 0.5s ease-in-out;
}
