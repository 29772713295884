@font-face {
    font-family: 'MuseoSans500';
    src: local('MuseoSans500'), url(../../../common/fonts/MuseoSans-500.otf) format('opentype');
}

.details--hr-modal {
    height: 1.5px !important;
}

.grid-credit-table {
    display: grid;
    grid-template-columns: 1.2fr 0.5fr 1fr;
    grid-template-rows: 1fr;
}

.grid-self-justify-end {
    justify-self: end;
}

.zIndex-over-20{
    z-index: 20;
}
.grid-self-justify-center {
    justify-self: center;
}


.flap-content-container {
    min-height: 65vh;
}

.performance-flap-button {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none !important;
    /*background-color: #727580;
    color: #434754;*/
    height: 38px;
    transition: background-color .15s ease-in-out;
    transition: color .30s ease-in-out;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.performance-flap-button:hover {
    color: #171A22;
}

.performance-flap-container {
    padding-left: 35px;
}

.performance-filter-button {
    height: 32px;
}

.perf-history-week {
    height: 40px;
    width: 184px;
}

.personal-sales-filter-hover:hover {
    background-color: #99ADE5;
    color: #FFFFFF;
    border: 1px solid #99ADE5;
}

.personal-sales-popup {
    position: absolute;
    z-index: 50;
    left: -210px;
    top: 50px;
    width: 392px;
    height: 400px;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 10px 10px 25px #a4a4d733;
}

.personal-sales-row-height {
    height: 72px;
}

.personal-sales-order{
    width: 20%;
}

.personal-sales-state{
    width: 15%;
}

.personal-sales-date{
    width: 15%;
}

.personal-sales-type{
    width: 17%;
}

.personal-sales-total{
    width: 10%;
}

.personal-sales-chevron{
    width: 5%;
}

.weekly-sales-modal-width {
    max-width: 608px !important;
}

.weekly-sales-modal-height {
    height: auto;
}

.weekly-sales-header {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3rem;
    padding-right: 2.5rem;
}

.weekly-sales-body {
    padding-bottom: 2rem;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.group-sales-row-height {
    height: 86px;
}

.performance-week-width {
    width: 10%;
}

.performance-name-week-sales-width {
    width: 30%;
}

.performance-name-request-status-width {
    width: 50%;
}

.performance-billed-width {
    width: 10%;
}

.performance-balance-width {
    width: 12%;
}

.performance-not-sent-width {
    width: 10%;
}

.performance-to-invoice-width {
    width: 10%;
}

.performance-row-picture-size {
    width: 32px;
    height: 32px;
}

.performance-svg-size > svg{
    width: 16px;
    height: 16px;
}

.performance-toltip-inline-container {
    display: inline;
    vertical-align: top;
}

.performance-no-results-text{
    padding-left: 100px;
    padding-right: 100px;
}

.user-tooltip {
    display: none;
    position: absolute;
    min-height: 341px;
    width: 288px;
    z-index: 50;
    left: 40px;
    top: 70px;
    
    border-radius: 1rem;
    box-shadow: 10px 10px 30px #A4A4D733;
}

.user-tooltip-content {
    min-height: 341px;
}

.user-tooltip-header {
    height: 68px;
}

.user-tooltip-body {
    min-height: 273px; 
    padding-top: 52px;
}

.tooltip-container:hover > .user-tooltip  {
    display: initial;
}

.user-tooltip:hover {
     display: initial;
}

.user-tooltip-social-container {
    width: 80%;
}





.billed-tooltip {
    display: none;
    position: absolute;
    width: 288px;
    min-height: 78px;
    z-index: 50;
    right: -25px;
    top: 40px;
    border-radius: 1rem;
    box-shadow: 10px 10px 30px #A4A4D733;
}

.billed-tooltip-content {
    min-height: 78px;
}

.bill-tooltip-container:hover > .billed-tooltip  {
    display: initial;
}


.billed-tooltip:hover {
    display: initial;
}

.help-modal-header-footer {
    height: 112px;
}

.help-modal-info-container {
    max-height: 384px;
    overflow-y: scroll;
}

.help-modal-ok-button {
    height: 40px;
}

.help-modal-content {
    height: 608px !important;
    width: 608px !important;
    max-height: 608px !important;

}

.qualified-switch-false {
    left: 2px;
}

.qualified-switch-true {
    right: 2px;
}

#qualifiedGroupsOrderBy {
    display: none;
}

.qualified-tooltip-container > #qualifiedGroupsOrderBy {
    display: initial;
}

.qualified-card {
    min-width: 392px; 
    border: 1px solid #CED2FF66; 
    border-radius: 16px;
    padding: 32px;
}

.qualified-card:hover {
    box-shadow: 0px 0px 50px 10px #A4A4D733;
}

.qualified-card-picture {
    width: 72px;
    height: 72px;
}

.hide-qualified-switch {
    height: 24px; 
    width: 44px; 
    padding-left: 2px; 
    padding-right: 2px;
}

.hide-qualified-switch > div {
    height: 20px; 
    width: 20px; 
}

.order-select-tooltip {
    width: 184px;
    height: 88px; 
    box-shadow: 10px 10px 25px #A4A4D755; 
    border-radius: 16px; 
    top: 56px; 
    padding: 17px
}

.qualified-card-social {
    display: grid; 
    grid-template-columns: repeat(auto-fill, 24px); 
    gap: 18px;
}

.qualified-card-max-width {
    max-width: 392px;
}

.qualified-svg-size{
    width: 14px;
}

.performance-filter-input {
    border: 3px solid #BCBCEB;
    /*border-style: double;
    border-color: #BCBCEB;*/
    height: 45px;
    border-radius: 15px !important;
}

/*input::-webkit-outer-spin-button,
input::-webkit-outer-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
  }*/

@media screen and (max-width: 1260px) {
    .order-select-tooltip {
        width: 129px;
    }
}

@media screen and (max-width: 1280px) {
    /*.performance-flap-button {
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border: none !important;
        height: unset;
        transition: background-color .15s ease-in-out;
    }*/

    .perf-history-week {
        height: 40px;
        width: 130px;
    }

    .personal-sales-popup {
        left: -240px;
    }

    .personal-sales-date{
        width: 12%;
    }

    .personal-sales-type{
        width: 20%;
    }

    /*.order-select-tooltip {
        width: 129px;
    }*/

    .qualified-card {
        padding: 24px;
    }

}

@media screen and (min-width: 1280px) and (max-width: 1900px) {
    .perf-history-week {
        height: 40px;
        width: 160px;
    }

    .qualified-card {
        padding: 24px;
    }
}

@media screen and (min-width: 1920px) {
    .perf-history-week {
        height: 40px;
        width: 184px;
    }
}


.grid--order-details {
    display: grid;
    grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr 1.5fr;
    grid-template-rows: 1fr;
    gap: 8px;
}

.grid--order-details--smaller {
    display: grid;
    grid-template-columns: 1.2fr 1.2fr 1fr 0.8fr 1fr 0.5fr;
    grid-template-rows: 1fr;
    gap: 8px;
}

.grid--order-details > svg {
    justify-self: end;
}
/* intento de efecto  */

.lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }
