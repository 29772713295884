
.config-modal-height {
    height: 666px;
}

.modal-header-styles {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background-color: #f8f9fa;
    height: 112px; 
    padding-left: 40px; 
    padding-right: 40px; 
    padding-top: 44px; 
    padding-bottom: 44px;
}

.config-section-container {
    width: 710px;
    padding-left: 40px;
    padding-right: 40px;
}

.config-picture-change-text {
    width: 392px;
    line-height: 21px;
    height: 41px;
}

.picture-and-icon-container {
    width: 72px;
}

.picture-change-icon {
    bottom: -5px;
    right: -10px;
    width: 32px;
    height: 32px;
}

.config-menu-button {
    height: 50px;
}

.selected-menu-button {
    background-color: #FCF6FE;
}

.config-continue-button-container-width {
    width: 40%;
}

.config-input-container-width {
    width: 60%;
}

.shadow-effect-config {
    box-shadow: -2px 48px 38px 0px rgb(255 255 255 / 99%) inset, 40px -20px 50px 35px rgb(255 255 255 / 90%);
    bottom: 0;
    width: 100%;
    z-index: 1900;
}

.profile-picture-updater-padding-none > div > div > div {
    padding: 0px !important;
}

.profile-picture-updater-svg-fill svg {
    fill: #456ECE;
}

.settings-picture-buttons-width {
    /*width: 160px;*/
    width: 200px;
    height: 40px;
}

.config-logout-button {
    width: 224px; 
    height: 40px; 
    margin-bottom: 40px;
}


/* @media screen and (min-width: 575px) {
.hover-pointer:hover {
    cursor: pointer;
}

@media screen and (min-width: 575px) {
    .modal-width {
        width: 350px !important;
    }
}

@media screen and (min-width: 992px) {
    .modal-width {
        width: 850px !important;
    }

    .modal-height {
        height: 530px;
    }
} */

