#nav-toggler {
    display: grid;
    position: fixed;
    top: 0px;
    width: 75px;
    height: 100%;
    z-index: 100;
    transition: width ease-out 0.5s;
    grid-template-columns: 75px;
    grid-template-rows: 85px 2fr 0.4fr;
}

#profile--status {
    grid-column-start: 1;
    grid-column-end: 2;
    right: 10px;
    bottom: 15px;
}

#profile--status > svg {
    position: absolute;
}

#profile-holder > img {
    justify-self: center;
    align-self: center;
}

#nav-toggler:hover #profile--status {
    right: 10px !important; 
}

#nav-toggler:hover {
    transition: width 0.05s ease-out;
    width: 300px;
    grid-template-columns: 300px;
}

#nav-toggler #error-handler-server #error-handler-server-content {
    display: none;
}

#nav-toggler #error-handler-server #error-handler-server-button {
    display: none;
}

#nav-toggler #error-handler-server #navbar-error-handler-svg { 
    position: relative;
    left: -4px;
    top: 4px;
}

#nav-toggler:hover #error-handler-server #error-handler-server-content,
 #nav-toggler:hover #error-handler-server #error-handler-server-button,
 #nav-toggler:hover #error-handler-server #navbar-error-handler-svg {
    display: flex;
    left: 0px;
    top: 0px;
}


#nav-toggler:hover #profile-info,
#nav-toggler:hover .nav-desc {
    display: inline;
    visibility: initial;
    justify-self: flex-start;
}

#nav-toggler:hover .nav-info {
    grid-template-columns: 75px auto 25px 15px;
    width: 100%;
}

#nav-toggler:hover .nav--btn-holder {
    padding-right: 10px;
}

#nav-toggler:hover .nav-spacer {
    width: 90%;
    transition: width 0.5s ease-in-out;
}

#nav-toggler:hover #nav-logo-alt {
    display: initial;
}

#nav-toggler:hover #nav-footer {
    grid-template-columns: 75px auto;
}

#nav-footer {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 75px;
    justify-items: center;
}

#nav-logo-alt {
    display: none;
    justify-self: flex-start;
}

.nav--btn-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px;
    gap: 16px;
}

.nav-desc {
    display: none;
    visibility: hidden;
}

.nav-info {
    position: relative;
    display: grid;
    cursor: pointer;
    justify-items: center;
    width: 75%;
    grid-template-columns: 75px;
    grid-template-rows: 20px;
    height: auto;
    background-color: transparent;
    border-radius: 0rem 1rem 1rem 0rem;
    padding: 2px 0px;
}

.nav-info:hover {
    background-color: rgba(255, 255, 255, 0.23) !important;
}

.nav-info-active {
    background-color: rgba(255, 255, 255, 0.23) !important;
}

.nav-spacer {
    width: 60%;
    background-color: #FFF;
    opacity: 0.2;
    height: 1px;
    transition: width 0.5s ease-in-out;
}

#nav-list {
    display: flex;
    flex-direction: column;
}

#profile-info {
    display: none;
    visibility: hidden;
}

#profile-holder {
    display: grid;
    grid-template-columns: 75px;
    grid-template-rows: 81px;
}

#nav-toggler:hover #profile-holder {
    grid-template-columns: 75px 1fr;
}

.nav-item-desc {
    justify-self: flex-start;
}

#nav-toggler .navbar--dropdown-menu {
    display: none !important;
}

#nav-toggler:hover .navbar--dropdown-menu {
    display: grid !important;
}

.navbar--dropdown-menu>a {
    justify-self: flex-start;
}


@media (min-width: 1800px) {
    #nav-toggler {
        display: grid;
        position: fixed;
        top: 0px;
        width: 300px;
        height: 100%;
        z-index: 100;
        transition: width ease-out 0.5s;
        grid-template-columns: 300px;
        grid-template-rows: 85px 2fr 0.4fr;
    }
    
    #nav-toggler #profile-info,
    #nav-toggler .nav-desc {
        display: inline;
        visibility: initial;
        justify-self: flex-start;
    }

    .nav-info {
        position: relative;
        display: grid;
        cursor: pointer;
        justify-items: center;
        width: 100%;
        grid-template-columns: 75px auto 25px 15px;
        grid-template-rows: 20px;
        height: auto;
        background-color: transparent;
        border-radius: 0rem 1rem 1rem 0rem;
        padding: 2px 0px;
    }
    
    #nav-toggler .nav--btn-holder {
        padding-right: 10px;
    }
    
    #nav-toggler .nav-spacer {
        width: 90%;
        transition: width 0.5s ease-in-out;
    }
    
    #nav-toggler #nav-logo-alt {
        display: initial;
    }
    
    #nav-toggler #nav-footer {
        grid-template-columns: 75px auto;
    }

    #profile--status {
        grid-column-start: 1;
        grid-column-end: 2;
        right: 10px;
        bottom: 15px;
    }
    
    #profile--status > svg {
        position: absolute;
    }
    
    #profile-holder > img {
        justify-self: center;
        align-self: center;
    }
    
    #nav-footer {
        position: relative;
        display: grid;
        align-items: center;
        grid-template-columns: 75px;
        justify-items: center;
    }
    
    #nav-logo-alt {
        display: none;
        justify-self: flex-start;
    }
    
    .nav--btn-holder {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0px;
    }
    
    .nav-desc {
        display: none;
        visibility: hidden;
    }
    
    
    .nav-spacer {
        width: 60%;
        background-color: #FFF;
        opacity: 0.2;
        height: 1px;
        transition: width 0.5s ease-in-out;
    }
    
    #nav-list {
        display: flex;
        flex-direction: column;
    }
    
    #profile-info {
        display: none;
        visibility: hidden;
    }
    
    #profile-holder {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 75px 1fr;
    }
    
    .nav-item-desc {
        justify-self: flex-start;
    }
    
    #nav-toggler .navbar--dropdown-menu {
        display: none !important;
    }
    
    #nav-toggler:hover .navbar--dropdown-menu {
        display: grid !important;
    }
    
    .navbar--dropdown-menu>a {
        justify-self: flex-start;
    }

    #nav-toggler #error-handler-server #error-handler-server-content,
 #nav-toggler #error-handler-server #error-handler-server-button,
 #nav-toggler #error-handler-server #navbar-error-handler-svg {
    display: flex;
    left: 0px;
    top: 0px;
}
}