.desc--grid-personal {
    display:  grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    align-items: center;
}

.desc--dashboard-bg {
    background: linear-gradient(123deg, rgba(250,250,252,1) 55%, #F5F6FF 100%);
}

.desc--personal-update > div {
    margin: 0px !important;
}

.desc--justify-self-end {
    justify-self: flex-end;
}

.desc--svg-adjustment > svg {
    width: 26px;
    height: 26px
}

.desc--grid-tree {
    display: grid;
    grid-template-columns: 1fr 114px 128px 114px;
}

.desc--grid-collapse {
    display: grid;
    grid-template-columns: 16px 1fr;
}

.desc--grid-collapse .desc--grid-collapse-line {
    height: 100%;
}

.desc--grid-container-line {
    height: auto;
}

.desc--grid-collapse-line-end {
    height: 51%;
}

.desc---grid-overwhole {
    grid-column-start: 1;
    grid-column-end: -1;
}

.desc-info-modal {
    width: 496px;
    height: 560px;
}

#desc-profile-grid {
    display: grid;
    grid-template-columns: 288px 1fr;
}
.lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }