#account-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 220px auto;
    overflow-x: none;
}

.account--card-shadow {
    box-shadow: 3px 3px 15px #A4A4D726;
}

#account-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

#account-header--btn-holder {
    justify-self: flex-end;
    align-self: center;
}

#account-header--btn-holder > button {
    align-items: center;
    width: 184px;
}

#account-body {
    display: grid;
    gap: 24px;
    grid-template-columns: 288px 1fr 288px;
}

#account-plan-grid {
    display: grid;
    grid-template-rows: 1fr 50px;
}

.rounded-account-cards {
    border-radius: 16px;
}

.account-listing {
    display: grid;
    grid-template-columns: 10px 1fr 100px;
    grid-template-rows: 1fr;
    align-items: center;
}

.account-listing-selected {
    font-weight: bolder ;
    border-radius: 8px;
    background-color: #ECF5FE;
}

.bubble-social {
    background: #5AAFF11A 0% 0% no-repeat padding-box;
}

#account--graph-holder {
    display: grid;
    position: relative;
    overflow-y: none;
    min-height: 275px;
    max-height: 275px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#account--graph-holder > svg {
    display: grid;
    position: relative;
    min-height: 270px;
    max-height: 270px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#account--graph-holder > svg text {
    position: relative;
    cursor: pointer;
    font-family: "DMSansMedium", Arial, Helvetica, sans-serif;
    src: local('DMSansBold'), url(../../../../app/common/fonts/DMSans-Medium.ttf) format('truetype');
}

#account--graph-holder::-webkit-scrollbar {
    display: none;
}

#account-header--title {
    max-width: 650px;
}

@media (max-width: 1440px) { 
    #account-body {
        gap: 24px;
        grid-template-columns: 288px 1fr 288px;
    }

}

@media (max-width: 1280px) { 

    .account-buttons {
        height: 47px;    
    }

    #account-header--btn-holder > button {
        align-items: center;
        width: 252px;
    }

    #account-body {
        gap: 24px;
        grid-template-columns: 344px auto 252px;
        justify-content: space-around;
    }

    #account-body > #account--graph-container  {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
    }

    #account-body > #account--graph-container #account--graph-holder {
        max-width: 100%;
    }

    #account-header--title {
        max-width: 450px;
    }

    #account-header--btn-holder {
        flex-direction: column;
        gap: 10px;
    }

    
}

.account-buttons {
    height: 40px;    
}

#ethic-content {
    height: 80px;
}

#account-balance--container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media (max-width: 1280px) {

    #account-header--btn-holder > button {
        align-items: center;
        width: 202px;
        padding: 5px 10px;
    }
    
    #account-balance--container {
        display: grid;
        width: 100%;
        grid-template-columns: 278px auto;
        gap: 24px;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    #ethic-content {
        height: 96px;
    }

    #account-balance--container > #ethic {
        width: 278px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    #account-balance--container > div {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    #account-body {
        grid-template-columns: 275px 1fr;
        grid-template-rows: 1fr auto;
        justify-content: space-around;
    }

    #account-header--title {
        max-width: 450px;
    }


    #account-body > #account--graph-container  {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
    }

    #account-header--btn-holder {
        flex-direction: column;
        gap: 10px;
    }

    
}
.lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }