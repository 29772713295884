.hover-on-mouse:hover {
    box-shadow: 3px 3px 15px #A4A4D733;
    border: 1px solid #D3EAFF;
    transition: box-shadow 0.5s ease-in;
}

@media (min-width: 1024px) {
    .bp--card-p {
        padding: 16px;
    }
    .bp--card-width {
        width: 208px
    }
    #business-plan--level-grid {
        display: grid;
        grid-template-columns: 24px 206px;
        grid-template-rows: auto;
        justify-items: center;
        height: fit-content;
    }
    .bp-earning--p-width {
        width: 125px;
    }
}

@media (min-width: 1280px) {
    .bp--card-p {
        padding: 24px;
    }
    .bp--card-width {
        width: 248px
    }
    #business-plan--level-grid {
        display: grid;
        grid-template-columns: 24px 248px;
        grid-template-rows: auto;
        justify-items: center;
        height: fit-content;
    }
    .bp-earning--p-width {
        width: 275px;
    }
}

@media (min-width: 1440px) {
    .bp--card-p {
        padding: 24px;
    }
    .bp--card-width {
        width: 248px
    }
    #business-plan--level-grid {
        display: grid;
        grid-template-columns: 24px 248px;
        grid-template-rows: auto;
        justify-items: center;
        height: fit-content;
    }
    .bp-earning--p-width {
        width: 295px;
    }
}

.hover-on-mouse {
    transition: box-shadow 0.5s ease-in;
}
.lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }