.edb-card-box-shadow {
    box-shadow: 10px 10px 25px #A4A4D733;
}

.edb-lastest-update-shadow {
    box-shadow: 10px 10px 25px #A4A4D733;
}

.edb-details-card-shadow {
    box-shadow: 3px 3px 15px #A4A4D733; 
}

.edb-form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

/***** css-1ouejwz-menu *****/   /***** End css-1ouejwz-menu *****/
.css-1ouejwz-menu {
    position: absolute !important;
    
    z-index: 20 !important;
}

.edb-card-radius {
    border-radius: 16px;
}

.edb-card-dimensions {
    width: 275px;
    min-height: 352px;
}

.edb-noscroll::-webkit-scrollbar {
    display: none;
}

.edb-details-menu-list {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    grid-template-rows: 1fr;
}

#edb-details--page {
    display: flex;
    width: 100%;
    justify-content: center;   
}

#edb-details--card-holder {
    gap: 1%;
    justify-content: space-between;
}

#edb--details-card-params {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1px 1fr;
    gap: 10px;
}

.edb--details-table {
    position: relative;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 1fr;
}

.edb-card-container {
    position: relative;
    display: grid;
    grid-template-rows: 180px 100px;
    grid-template-columns: 1fr;
}

#edb-card-guests {
    position: relative;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 40px 1fr auto;
}

#edb-card-guests-content { 
    grid-column-start: 1;
    grid-column-end: 3;

    position: relative;
    display: grid;
    grid-template-columns: 95px auto 1fr 1fr;
    grid-template-rows: 1fr;
}

#edb-card-links {
    justify-self: flex-end;
    grid-column-start: 1;
    grid-column-end: 3;

}

.edb-background {
    background: transparent linear-gradient(142deg, #FAFAFC 0%, #F5F6FF 100%) 0% 0% no-repeat padding-box;
}

.no-edbs-icon {
    width: 80px;
    height: 80px;
    padding: 23px;
    background: #456ECE12 0% 0% no-repeat padding-box;
}

.no-edbs-icon > svg > path {
    fill: #456ECE;
}

.no-edbs-width {
    width: 520px;
}

.edb-dashboard-section-height {
    height: 410px;
}

.carousel-arrow-container{
    top: 40%;
}

.arrow-dimensions > svg {
    width: 24px;
    height: 24px
}

.previousCarouselArrow {
    position: absolute;
    left: -28px;
    width: 24px;
    height: 24px;
}

.previousCarouselLink {
    border-radius: 50%;
    background-color: #5AAFF1;
    color: #FFFFFF;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.previousCarouselLink:hover {
    color: #456ECE;
}

.edb-disabled-link {
    /*background-color: #DDDDDD;*/
    cursor: none;
    pointer-events: none;
}

.edb-disabled-link > svg > circle {
    /*background-color: #DDDDDD;*/
    fill: #DDDDDD;
}

.nextCarouselArrow {
    position: absolute;
    right: -28px;
    width: 24px;
    height: 24px;
}

.edb-chevron-right-padding {
    padding-left: 3px;
}

.edb-chevron-left-padding {
    padding-right: 3px;
}

.edb-update-modal-padding {
    padding: 3rem;
}

.flap-button {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none !important;
    /*background-color: #727580;
    color: #434754;*/
    width: 184px;
    height: 38px;
    transition: background-color .15s ease-in-out;
}

.flap-container {
    padding-left: 35px;
}

.row-height {
    height: 60px;
}

.edb-empty-height {
    height: 60vh;
}

.red-dot-hover:hover > div {
    background-color: #F4BABC !important;
}

.edb-filter-button {
    width: 256px !important;
    height: 32px !important;
}

.edb-four-steps {
    width: 288px;
}

.edb-details--cards {
    width: 390px;
    min-height: 328px;
}

.edb-details--cards-gap {
    gap: 14px;
}


@media (min-width: 1024px) {
    .edb-details--cards {
        width: 410px;
    }
    .edb-details--cards-gap {
        gap: 12px;
    }
    .edb-four-steps {
        width: 275px;
    }
    .edb-scroll-slider{
        width: 854px;
    }
    .edb-card-dimensions {
        width: 274px;
        min-height: 352px;
    }
    .edb-dashboard--slider-gap{
        gap: 16px
    }
}
@media (min-width: 1280px) { 
    .edb-details--cards {
        width: 520px;
    }
    .edb-four-steps {
        width: 252px;
    }
    .edb-details--cards-gap {
        gap: 12px;
    }
    .edb-card-dimensions {
        width: 252px;
        min-height: 352px;
    }
    .edb-scroll-slider{
        width: 1080px;
    }
    .edb-dashboard--slider-gap{
        gap: 24px
    }
    
}

@media (min-width: 1440px) {
    .edb-card-dimensions {
        width: 288px;
        min-height: 352px;
    }
    .edb-four-steps {
        width: 288px;
    }
    .edb-scroll-slider{
        width: 1244px;
    }
    .edb-dashboard--slider-gap{
        gap: 24px
    }
    
}

@media (min-width: 1441px) {
    .container--no-padding {
        padding: 0px !important;
    }
}

@media (min-width: 1800px) { 
    .edb-scroll-slider{
        width: 1224px;
    }
    .edb-details--cards-gap {
        gap: 22px;
    }
    .edb-dashboard--slider-gap{
        gap: 24px
    }
    .edb-details--cards {
        width: 392px;
    }
    .container--no-padding {
        padding: 0px !important;
    }
    #edb--details-card-params {
        grid-template-rows: auto 1px 1fr;
        gap: 32px;
    }

}



.hover-controller > .hover-target {
    display: none;
}

.hover-target:hover {
    display: flex;
}

.hover-controller:hover > .hover-target {
    display: flex;
}
.lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }
