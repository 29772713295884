.carousel-login-wrapper li.slide{
    min-height: 100vh !important;
    user-select: none;
}

.carousel-login-wrapper img {
    object-fit: cover;
    height: 100vh;
    object-position: center;
}

@media (max-width:500px) {
    .carousel-login-wrapper{
        display:none;
    }  
}