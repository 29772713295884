#login-start {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    padding: min(60px, 18vh) 0 40px 0;
}

#login--profile--canvas > div > div > div > div > div {
    width: 250px !important;
    height: 250px !important;
}

#login--profile--canvas > div > div > div > div > div > canvas {
    width: 250px !important;
    height: 250px !important;
}

#login--profile--canvas > div > div > div > div > canvas {
    width: 250px !important;
    height: 250px !important;
}

.input-borders {
    border: #8889db solid 1px !important ;
}

.input-disabled {
    background-color: #EDEDED !important;
    cursor: disabled !important ;
    border-color: #908F9A !important;
    color: #908F9A !important;
}

input:focus::placeholder {
    color: transparent !important;
  }

#login--btn-holder {
    display: grid;
    grid-template-rows: 1fr auto;
    margin-top: 1rem;
    justify-items: center;
}

#login-layout {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 70vh auto;
}

#login-layout h2 {
    width: 100%;
    font-size: 32px;
}

#login--confirm-sms {
    min-width: 75%;
}

#login-form {
    width: 392px;
    max-width: 35vw;
}

@media (max-width: 1080px) {
    #login-start {
        padding: 40px 0 40px 0;
    }
}

@media (max-width: 500px) {
    #login-form {
        width: 70vw;
        max-width: 392px;
    }
    #login-start {
        grid-template-columns: 1fr;
    }
}

#login--confirm-sms > input {
    text-align: center;
    border: 1px solid #8889DB;
    font-size: 26px;
    width: 48px;
    height: 56px;
}

.login-profile--card-selected {
    border: 4px solid #5AAFF1;
}

.login-profile--card--not-selected {
    border: 4px solid #FFFFFF;
}

#login--confirm-sms > div > input {
    text-align: center;
    border: 1px solid #8889DB;
    font-size: 26px;
    width: 48px;
    height: 56px;
}

#login--select-container {
    border: 1px solid #ced4da;
    padding: 5px 10px;
    border-radius: 0.5rem;
}

#login--phone-container {
    width: 100%;
}

#login--phone-zone {
    width: 80px;
}
#login--phone-number {
    width: 288px;
}

#login--continue {
    transition: opacity 0.5s ease-in-out;
}

#login--continue:hover {
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;
}

#login--profile-container {
    height: auto;
}

#login--profile-container > div {
    position: relative;
}

._1Fq_tZ_l7SwH_r_a_SNOtA > div {
    max-width: 160px !important;
}


@media (min-width: 1201px) {
    #login-layout h2 {
        width: 75%;
        font-size: 40px;
    }
    #login--confirm-sms {
        min-width: 250px;
        width: 250px;
    }
    #login--profile-container {
        height: auto;
    }  
}

.lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }