.event-card{
    min-height: 102px;
    opacity: 75%;
}

.calendar-row{
    height: 108px;
}

.calendar-top-left-borders > div{
    border-left: 1px solid #DDDDDD;
    border-top: 1px solid #DDDDDD;
}

.calendar-right-border {
    border-right: 1px solid #DDDDDD;
}

.calendar-blue-left-border {
    border-left: 1px solid #4E6DC7 !important;
}

.calendar-bottom-border > div {
    border-bottom: 1px solid #DDDDDD;
}

.calendar-no-borders {
    border-top: none !important;
    border-left: none !important;
    border-bottom: none !important;
}

.calendar-square-padding > div {
    padding-top: 8px;
    padding-left: 8px;
}

.calendar-number-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar-dot{
    padding-bottom: 2px;
}

.calendar-borders {
    border: 0.5px solid #DDDDDD;
}

.calendar-period-day-highlight {
    background-color: rgba(69, 110, 206, 0.05);
}

.calendar-week-number{
    height: 108px;
    padding-top: 1rem;
}

.calendar-spinner{
    height: 10px !important;
    width: 10px !important;
    font-size: 1px !important;
}

.calendar-weekday-width{
    width: 14.2%;
}

.today-button {
    width: 184px;
}

.month-and-year-display{
    width: 230px
}

.calendar-and-events-container{
    max-height: 75vh;
}

.calendar-container {
    width: 75%;
}

.weekday-blank-space {
    width: 5%;
}

.weekday-container {
    width: 95%;
    height: fit-content;
}

.calendar-day-container {
    width: 95%;
}

#calendar-modal--link:hover {
    color: #5AAFF1 !important;
}

.calendar-day{
    height: 108px;
    width: 14.2%;
}

.calendar-event-section {
    width: 25%;
    overflow-y: scroll;
}

.calendar-event-title {
    width: 25%;
}

.calendar-day--hover-effect:hover {
    background-color: rgba(236,240,250, 1) ;
    cursor: pointer;
}

@media screen and (max-width: 1280px) {
    .calendar-week-number{
        padding-left: none;
    }
}
.lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }