.gg-primary--importance {
  width: 500px;
  height: 240px;
}

.gg-cards--importance-consult {
  width: 184px;
  height: auto;
}

.gg-cards--importance {
  width: 238px;
  height: 356px;
}

.gg-cards--ecommerce {
  width: 390px;
}

.gg-cards--gaps {
  gap: 24px;
}

.gg-card--course-plan {
  width: 392px;
}

@media (max-width: 1280px) {
  .gg-primary--importance {
    width: 500px;
    height: 240px;
  }

  .gg-cards--importance-consult {
    width: 246px;
    height: auto;
  }

  .gg-cards--importance {
    width: 238px;
    height: 356px;
  }

  .gg-cards--ecommerce {
    width: 522px;
  }

  .gg-card--course-plan {
    width: 527px;
  }
}

@media (max-width: 1024px) {
  .gg-primary--importance {
    width: 418px;
    height: 252px;
  }

  .gg-cards--importance {
    width: 202px;
    height: 382px;
  }

  .gg-cards--importance-consult {
    width: 196px;
    height: auto;
  }

  .gg-cards--ecommerce {
    width: 410px;
  }

  .gg-cards--gaps {
    gap: 14px;
  }

  .gg-card--course-plan {
    width: 420px;
  }
}
.lightui1 {
  border-color: #bdbdbd;
  border-radius: 2px;
  padding: 20px;
  background: #ffffff;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes prideShimmer {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}
._4-u5 {
  background-color: #ffffff;
}

._4-u7 {
  background-color: #ffffff;
}

._57d8 {
  background-color: #ffffff;
}

._4-u8 {
  background-color: #ffffff;
}
