#vimeo--holder iframe {
 height: 566px;
 width: 338px ;
 
}

#vimeo-asider iframe {
    height: 400px !important ;
    width: inherit !important ;
}

#vimeo--progress  {
    display:  flex;
    align-items: center;
    height: 100%;
}

#vimeo--progress > div > div  {
    background-color: #F06E69;
}

#vimeo--progress > div {
    background-color: gray !important;
}


#vimeo--progress * {
    height: 3px !important;
}

#vimeo--holder > div {
    display: grid;
    align-items: center;
   }

#vimeo--displayer {
    position: fixed;
    display: block;
    width: "100%" ;
     height: "100%";
     top: "0";
     left: "0";
     z-index: 990;
}

@media screen and (min-width: 1800px) {
    #vimeo--holder iframe {
        height: 924px;
        width: 428px ;
        
       }

       #vimeo-asider iframe {
        height: 596px !important ;
        width: 345px !important ;
    }
    
    #vimeo--progress  {
        display:  flex;
        align-items: center;
        height: 100%;
    }
    
    #vimeo--progress > div > div  {
        background-color: #F06E69;
    }
    
    #vimeo--progress > div {
        background-color: gray !important;
    }
    
    
    #vimeo--progress * {
        height: 5px !important;
    }
    
    #vimeo--holder > div {
        display: grid;
        align-items: center;
       }
    
    #vimeo--displayer {
        position: fixed;
        display: block;
        width: "100%" ;
         height: "100%";
         top: "0";
         left: "0";
         z-index: 990;
    }   
  }