.status-account--grid-table {
    display: grid;
    grid-template-columns: 4fr 1.5fr 1.5fr 1.4fr;
}
.lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }
