#display-card-direction-grid {
    display: grid;
    grid-template-columns: auto auto;
    flex-wrap: wrap;
    grid-template-rows: repeat(auto-fill);
}

.clients-details-header-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.clients-details-header-buttons #client-delete-button {
    grid-column-end: 2;
    grid-column-start: 3;
    justify-self: flex-end;
}

.client-selected-row {
    background-color: #ECF5FE;
    border-radius: 8px;
}

.border-bottom-1 {
    border-bottom: 1px solid #dddddd;
}

.border-bottom-2 {
    border-bottom: 1px solid #dddddd;
}

.border-bottom-2:last-child {
    border-bottom: none;
}

.border-bottom-1:hover, .border-bottom-2:hover {
    opacity: 0.6;
}

@media (min-width: 1025px) {
    #display-card-direction-grid {
        display: grid;
        grid-template-columns: auto auto;
        flex-wrap: wrap;
        grid-template-rows: repeat(auto-fill);
    }
    
    .clients-details-header-buttons {
        display: grid;
    }
    .clients-details-header-buttons #client-delete-button {
        grid-column-end: 4;
        grid-column-start: 3;
        justify-self: flex-end;
    }
}

.custom-select-placeholder {
    font-family: "MuseoSans500", Arial, Helvetica, sans-serif;
    font-size: 16px ;
    color: #908f9a ;
}