#module-three--personal-sale ,
#module-three--modal, #module-four--group-sale ,
#module-four--modal {
    position: absolute;
}



@media (min-width: 1023px) {
    #module-four--group-sale{
        top: 465px;
        right: 305px;
        width: 90px;
        height: 100px
    }
    #module-three--personal-sale {
        top: 420px ;
        left: 90px;
        width: 365px;
        height: 60px
    }
    #module-three--modal {
        right: 120px;
        top: 410px;
        width: 355px;
        height: 205px
    }
    
}

@media (min-width: 1280px) {
    #module-three--personal-sale {
        top: 420px;
        left: 90px;
        width: 480px;
        height: 60px
    }
    #module-three--modal {
        right: 240px;
        top: 400px;
        width: 355px;
        height: 205px
    }
    #module-four--group-sale{
        top: 460px;
        right: 400px;
        width: 90px;
        height: 100px
    }
    #module-extra--modal {
        
    }
}

@media (min-width: 1440px) {
    #module-three--personal-sale {
        top: 420px;
        left: 90px;
        width: 560px;
        height: 60px
    }
    #module-three--modal {
        right: 320px;
        top: 400px;
        width: 355px;
        height: 205px
    }
    #module-four--group-sale{
        top: 455px;
        right: 488px;
        width: 90px;
        height: 100px
    }
    #module-extra--modal {
        
    }
}

@media (min-width: 1799px) {
    #module-three--personal-sale {
        top: 420px;
        left: 23%;
        width: 550px;
        height: 60px
    }
    #module-three--modal {
        right: 21%;
        top: 400px;
        width: 355px;
        height: 205px
    }
    #module-four--group-sale{
        top: 455px;
        right: 520px;
        width: 90px;
        height: 100px
    }
    #module-four--modal{
        top: 330px;
        right: 502px;
        width: 90px;
        height: 100px
    }
}

@media (min-width: 1919px) {
    #module-three--personal-sale {
        top: 415px;
        left: 24.5%;
        width: 550px;
        height: 60px
    }
    #module-three--modal {
        right: 22%;
        top: 400px;
        width: 355px;
        height: 205px
    }
    #module-four--group-sale{
        top: 460px;
        right: 582px;
        width: 90px;
        height: 100px
    }
    #module-four--modal{
        top: 310px;
        right: 500px;
        width: 90px;
        height: 100px
    }
    #module-extra--modal {
        
    }
}