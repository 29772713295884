header {
    display: grid;

    grid-template-columns: 1fr 1.2fr 1fr;
    gap: 10px;
    grid-template-rows: 80px;
    justify-items: center;
}

.spacing--modal {
    margin-left: 75px;
}

#header-nav--spacing {
    padding-left: 75px;
    position: fixed;
    
    top: 0px;
    left: 0px;

    height: 80px;
    width: 100%;

    z-index: 50;
}

#header--bell {
    position: relative;
}

#header-nav--spacing > div {
    width: 100%;
}

#header--notfication-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#header--notfication-scroll::-webkit-scrollbar {
    display: none;
}

.header-two-cols {
    grid-template-columns: 1.5fr 0.5fr ;
}

#header--progressbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;
}

#header--progressdesc {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
}

#header--model-notific {
    position: absolute;
    z-index: 50;
    left: -470px;
    top: 50px;
    width: 496px;
    height: 608px;
    border-radius: 16px ;
    padding: 40px;
    box-shadow: 10px 10px 25px #A4A4D733;
}


.model-plus--row {
    display: grid;
    gap: 5px;
    grid-template-columns: 25px 1fr 50px;
}

.model-plus--row svg {
    width: 20px !important;
    font-size: 20px;
}

.model-plus--row > * {
    align-self: center;
}

#hover-counter {
    margin-left: 15px;
    border-radius: 1rem;
    width: 22px;

    color: white;
    cursor: pointer;
    top: -0px !important;
    right: -5px;
    background-color: red;
}

#header--plusBtn{
    transition: all 0.5 ease-in-out;
    z-index: 65;
}

#header--model-plus {
    display: none;
    position: fixed;
    height: 200px;
    width: 250px;
    z-index: 50;
    right: 60px;
    
    border-radius: 1rem;
}

#header--model-plus > ul {
    position: relative;
    border-radius: 1rem;
    top: 65px;
    z-index: 55;
}

#header--plusBtn:hover ~ #header--model-plus  {
    display: initial;
}

#header--model-plus:hover {
    display: initial;
}

#header--plusBtn #header--rotateCross {
    transition: all 0.5s ;
    font-size: 14px;
}

#header--plusBtn:hover #header--rotateCross {
    transform: rotate(45deg);
    transition: transform 0.5s ;
}

#header--btn-holder {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 80px;
    gap: 35px;
    justify-items: center;
    align-items: center;
    justify-self: flex-end;
}

#header--center {
    display: grid;
    width: 100%;
    grid-template-rows: 1fr 1fr;
}

#header--title {
    display: flex;
    justify-self: flex-start;
    align-items: center;
}

#header--btn-holder > i {
    width: 45px;
    height: 100px;
}

#header--plusBtn:hover {
    background: #7A9CED;
}

.header-btn-plus-button-width {
    width: 128px;
}

@media (min-width: 1023px) {
    header {
        grid-template-columns: 1fr 1.2fr 1fr;
        gap: 10px;
    }
    #header--model-notific {
        right: 120px;
    }

    #header--model-plus {
        left: -50%;
    }
    
    .header-btn-plus-button-width {
        width: 124px;
    }
    #header--center {
        width: 348px;
    }
}

@media (min-width: 1280px) {
    #header-btn-plus-button {
        width: 184px;
    }
    #header--center {
        width: 344px;
    }
    
}

@media (min-width: 1440px) {
    .header-btn-plus-button-width {
        width: 184px;
    }
    #header--model-plus {
        left: -15%;
    }

    #header--center {
        width: 392px;
    }
    
}

@media (min-width: 1441px) and (max-width: 1799px) {
    
    #header-nav--spacing > div {
        width: 1222px;
    }
    #header--bell {
        position: absolute;
        right: -70px;
    }
    #header--btn-holder {
        grid-template-columns: 1fr;
    }
}


@media (min-width: 1800px) {

    .spacing--modal {
        margin-left: 300px !important;
    }

    #header-nav--spacing {
        padding-left: 300px;
        z-index: 50;
    }
    #header--bell {
        position: absolute;
        right: -90px;
    }
    #header--btn-holder {
        grid-template-columns: 1fr;
        position: absolute;
        right: -0px;
    }
    .header-btn-plus-button-width {
        width: 184px;
    }
    #header--model-plus {
        left: -14%;
    }
    #header--center {
        width: 392px;
    }
    
    #header-nav--spacing > div {
        width: 1222px;
    }
    
}