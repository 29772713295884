
.graph-holder {
    display: grid;
    position: relative;
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr 0.25fr;

    width: 90%;
    height: 100%;
    padding: 20px 0px;
    margin: 0;

    justify-items: center;
    align-items: center;
} 

.graph-holder .graph-footer {

    display: flex;

    border-radius: 1rem;
    
    justify-items: center;
    align-items: center;
    padding:  5px 5px;

    font-size: 12px;
}

.graph-holder .graph-footer > p {
    margin: 0;
    padding: 0px 5px;
}

#canvas-dognut {
    font-family: "DMSansBold", Arial, Helvetica, sans-serif;
    width: inherit !important;
    height: inherit !important;
}

#dashboard--dognut--gap::after  {
    content: "Ganancia potencial total del periodo";
    top: 260px;
    width: 50%;     
    height: 50px;
    color: gray;
    font-family: "MuseoSans500", Arial, Helvetica, sans-serif;
    font-size: 12px;
}

#dashboard--dognut::after {
    content: "Ganancia potencial total del periodo";
    top: 250px;
    width: 50%;     
    height: 50px;
    color: gray;
    font-family: "MuseoSans500", Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.dashboard--dognut-gap::after{
    position: absolute;
    content: "Pendiente";
    font-size: 30px;
    color: #908F9A;
    font-family: "MuseoSans700", Arial, Helvetica, sans-serif;
    width: 24px;
    height: 24px;
    top: 35px;
    right: 60px;
}

@media (min-width: 1025px) {

    #dashboard--dognut::after {
        top: 255px;
    }
    #dashboard--dognut--gap::after  {
        top: 280px;
    }

    .dashboard--dognut-gap{
        top: 205px;
    }
    
    .dashboard--dognut-gap::after{
        top: 30px;
    }
}

#dashboard--dognut-prev{
    position: fixed;
}

#dashboard--dognut-prev-text {
    top: 170px;
    right: 105px;
    width: 35%;
    height: 50px;
    color: #908F9A;
    font-family: "MuseoSans500", Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.container--dognut {
    position: relative;
    display: block;
}

.container--dognut::after {
    display: flex;
    position: absolute;
    text-align: center;
    
}

.qualification--grid {
    display: grid;
    height: auto;
    min-height: 90%;
    grid-template-rows: 90% 10%  ;
}

.graph-listing {
    display: grid;
    padding-left: 16px;
    padding-right: 16px;
    grid-template-columns: 1.75fr 1.2fr 1.3fr 0.8fr;
}

.graph-listing-row {
    text-align: right;
    justify-self: flex-end;
}

.graph-listing-row-flexEnd {
    justify-self: flex-end;
}

.graph-limit-canvas-width > canvas {
    width:  inherit !important;
    height:  inherit !important;
}


@media (min-width: 1024px) {

    .graph-listing {
        padding-left: 24px;
        padding-right: 24px;
        grid-template-columns: 1.4fr 1.2fr 1.5fr 0.8fr;
    }
    .dashboard--dognut-gap{
        position: absolute;
        width: 24px;
        height: 24px;
        top: 180px;
        right: 140px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='ic-clock-md' transform='translate(14705 6558)'%3E%3Cpath id='vector' d='M27 15A12 12 0 1 1 15 3 12 12 0 0 1 27 15Z' transform='translate(-14708 -6561)' fill='%23dcb632'/%3E%3Cpath id='vector-2' data-name='vector' d='M18 9v7.2l4.8 2.4' transform='translate(-14711 -6560.701)' fill='%23dcb632' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
    }

    .dashboard--dognut-gap::after{
        position: absolute;
        content: "Pendiente";
        font-size: 30px;
        color: #908F9A;
        font-family: "MuseoSans700", Arial, Helvetica, sans-serif;
        width: 24px;
        height: 24px;
        top: 35px;
        right: 60px;
    }

    .graph-holder {
        display: grid;
        position: relative;
        grid-template-columns: 0.8fr 1.2fr;
        grid-template-rows: 1fr 0.25fr;
    
        height: 100%;
        width: 90%;
        padding: 10px 1%;
        margin: 0;
        gap: 10px;

        justify-items: center;
        align-items: center;
    }
    
    .graph-holder .graph-footer {
        grid-column-start: 1;
        grid-column-end: 3;
    
        display: flex;
    
        border-radius: 1rem;
        
    
        justify-items: center;
        align-items: center;
        padding:  5px 5px;
    
        font-size: 10px;
    }
    
    .graph-holder .graph-footer > p {
        margin: 0;
        padding: 0px 5px;
    }

    .graph-holder > canvas {
        width: 250px !important;
        height: 250px !important;
    }
    
    
}

@media (min-width: 1280px) {
    .dashboard--dognut-gap::after{
        position: absolute;
        content: "Pendiente";
        font-size: 30px;
        color: #908F9A;
        font-family: "MuseoSans700", Arial, Helvetica, sans-serif;
        width: 24px;
        height: 24px;
        top: 35px;
        right: 60px;
    }

    .dashboard--dognut-gap{
        position: absolute;
        width: 24px;
        height: 24px;
        top: 200px;
        right: 155px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='ic-clock-md' transform='translate(14705 6558)'%3E%3Cpath id='vector' d='M27 15A12 12 0 1 1 15 3 12 12 0 0 1 27 15Z' transform='translate(-14708 -6561)' fill='%23dcb632'/%3E%3Cpath id='vector-2' data-name='vector' d='M18 9v7.2l4.8 2.4' transform='translate(-14711 -6560.701)' fill='%23dcb632' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
    }

}
