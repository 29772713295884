.modal-body-margin-padding{
    margin: 0px 40px 40px 40px !important;
    /*padding: 40px 0px 0px 0px !important;*/
   padding: 0px !important;
}

.modal-header-padding{
    padding: 44px 40px !important;
}

.shadow-effect {
    box-shadow: -2px 48px 38px 1px rgb(255 255 255 / 99%) inset, 0px -20px 50px 10px rgb(255 255 255 / 90%);
    bottom: 0;
    width: 100%;
    height: 40px;
    z-index: 1900;
}

.shadow-effect-reverse {
    box-shadow: -2px 48px 38px 1px rgb(255 255 255 / 99%) inset, 0px -10px 20px 10px rgb(255 255 255 / 90%);
    bottom: 0;
    width: 100%;
    height: 40px;
    z-index: 1900;
}

.shadow-effect-two {
    box-shadow: -2px 48px 38px 0px rgb(255 255 255 / 99%) inset,   0px -19px 22px 4px rgb(255 255 255 / 90%);
    bottom: 0;
    width: 100%;
    height: 40px;
    z-index: 1900;
}

.cancel-hr--margin > hr {
    margin: 0px !important;
}

.p-tag-margin-bottom > p {
    margin-bottom: 1rem !important;
}

.p-tag-gray-text-color > p {
    color: #908F9A;
}

.politics-modal-content-styles{
    border-radius: 15px !important;
    max-height: 600px !important;
}

.politics-modal-dialog-styles{
    max-width: 912px !important;
}