.modal-arrow {
    content: "";
    display: block;
    position: absolute;
    top: 15%;
    bottom: auto;
    left: 345px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 30px 15px 0;
    border-color: transparent #ffffff transparent transparent;
}

.modal-arrow-2 {
    content: "";
    display: block;
    position: absolute;
    top: 15%;
    bottom: auto;
    left: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 50px 25px 0;
    border-color: transparent #ffffff transparent transparent;
}
.cards-Offers {
  width: 288px;
  height: 367px;
}
.dashboard-modal-grid-offers {
  display: flex;
  flex-direction: row;
}
.table--header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 88px;
  padding-top: 33px;
  padding-right: 88px;
  padding-bottom: 33px;
}
.table--header--1eraGen {
  display: grid;
  grid-template-columns: 1.7fr 1.5fr 1fr 1fr;
  padding-left: 88px;
  padding-top: 20px;
  padding-right: 88px;
  padding-bottom: 20px;
}
.table--header--1eraGenList {
  display: grid;
  grid-template-columns: 1.7fr 1.5fr 1fr 1fr;
  padding-left: 88px;
  padding-top: 20px;
  padding-right: 88px;
  padding-bottom: 20px;
}
.table--header--1eraGenListShort {
  display: grid;
  grid-template-columns: 1.7fr 1.5fr 1fr 1fr;
  padding-left: 17px;
  padding-top: 20px;
  padding-right: 88px;
  padding-bottom: 20px;
}
.padding--table--header--1eraGenList {
  padding-left: 88px;
  padding-top: 33px;
  padding-right: 88px;
  padding-bottom: 33px;
}
.No--padding--table--header--1eraGenList {
  padding-left: 88px;
  padding-right: 88px;
}
.table--header > span {
  justify-self: flex-end;
}
@media screen and (min-width: 1024px) {
  .dashboard-group-gap {
    gap: 16px
  }
  .cards-Offers {
    width: 273px;
    height: 367px;
  }
  .dashboard-group-min-width {
    min-width: 360px
  }
}

@media screen and (min-width: 1280px) {
  .dashboard-group-gap {
    gap: 16px
  }
  .dashboard-group-min-width {
    min-width: 454px
  }
}

@media screen and (min-width: 1440px) {
  .dashboard-group-gap {
    gap: 32px
  }
}


  .modal-arrow-rotate {
    transform: rotate(180deg);
  }

  .modal-close-icon > svg {
    width: 10px;
    height: 10px;
  }

  .modal-checkmark-icon > svg {
    width: 15px;
    height: 15px;
  }

  .modal-arrow-icon > svg {
    width: 12px;
    height: 12px;
  }

  .background-step-1 {
    background-image: url("../../../common/img/step1Template.png");
  }

  .background-step-2 {
    background-image: url("../../../common/img/step2Template.png");
  }

  .background-step-3-leader {
    background-image: url("../../../common/img/step3LeaderTemplate.png");
  }

  .background-step-3-consultant {
    background-image: url("../../../common/img/step3ConsultantTemplate.png");
  }

  .background-step-4 {
    background-image: url("../../../common/img/step4Template.png");
  }

  .background-step-5 {
    background-image: url("../../../common/img/step5Template.png");
  }

  .background-properties {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .background-properties-cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .lightui1{
    border-color:#bdbdbd;
    border-radius:2px;
    padding: 20px;
    background: #ffffff;
}
    
    @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0
        }
        100% {
          background-position: 468px 0
        }
      }
      
      @keyframes prideShimmer {
        from {
          background-position: top left
        }
        to {
          background-position: top right
        }
      }
      ._4-u5 {
        background-color: #ffffff
      }
      
      ._4-u7 {
        background-color: #ffffff
      }
      
      ._57d8 {
        background-color: #ffffff
      }
      
      ._4-u8 {
        background-color: #ffffff
      }